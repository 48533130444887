$(document).ready(function() {
    $("site__background-home").css("opacity", "1");
    $("site__background-main").css("opacity", "1");
    $("body").css("opacity", "1");
});

/*---------- Открытие мобильного меню ---------*/
$('.menu__btn').on('click', function(){
    if($('.mmenu').hasClass('nmenu__open')) {
        $('.mmenu').removeClass( 'nmenu__open' )
    }else {
        $('.mmenu').addClass('nmenu__open')
    }
    if($('.mmenu__overlay').hasClass('mmenu__overlay-open')) {
        $('.mmenu__overlay').removeClass( 'mmenu__overlay-open' );
        $('html').css('overflow-y', 'scroll'); // включаем скролл*/
    }else {
        $('.mmenu__overlay').addClass('mmenu__overlay-open');
        $('html').css('overflow-y', 'hidden'); // выключаем скролл*/
    }
    if($('.header').hasClass('nmenu__header')) {
        $('.header').removeClass( 'nmenu__header' )
    }else {
        $('.header').addClass('nmenu__header')
    }
})
/*---------- /Открытие мобильного меню ---------*/

/*---------- Переключение блоков контента на странице товара ---------*/
$(document).ready(function(){
    $('.product__tabs-panel-tabs a').click(function(e) {
        e.preventDefault();
        $('.product__tabs .active').removeClass('active');
        $(this).addClass('active');
        var tab = $(this).attr('href');
        $('.product__tabs-content1').not(tab).css({'display':'none'});
        $(tab).fadeIn(400);
    });
});
/*---------- /Переключение блоков контента на странице товара ---------*/

/*---------- Переключение авторизации по SMS/EMAIL ---------*/
$(document).ready(function(){
    $('.login__buttons a').click(function(e) {
        e.preventDefault();
        $('.login__popup-wrapper .active').removeClass('active');
        $(this).addClass('active');
        var tab = $(this).attr('href');
        $('.login__popup-method').not(tab).css({'display':'none'});
        $(tab).fadeIn(400);
    });
});
/*----------/Переключение авторизации по SMS/EMAIL ---------*/

$(document).ready(function(){
    $('.checkout-v2__wrapper__left-btns a').click(function(e) {
        e.preventDefault();
        $('.checkout-v2__wrapper__left .checkout-v2__wrapper__left-btn-active').removeClass('checkout-v2__wrapper__left-btn-active');
        $(this).addClass('checkout-v2__wrapper__left-btn-active');
        var tab = $(this).attr('href');
        $('.checkout-tab').not(tab).css({'display':'none'});
        $(tab).fadeIn(400);
    });
});

$(document).ready(function(){
    $('.product__left-images-bar a').click(function(e) {
        e.preventDefault();
        $('.product__left-images-main .active').removeClass('active');
        $(this).addClass('active');
        var tab = $(this).attr('href');
        $('.product__left-images-main').not(tab).css({'display':'none'});
        $(tab).fadeIn(400);
    });
});

